const KEY = 'lnch-res-store'; /* do not edit */

// SET STORAGE VALUE
export const setLocalStorageValue = value => {
  return localStorage.setItem(KEY, value);
};

// GET STORAGE VALUE
export const getLocalStorage = () => {
  return localStorage.getItem(KEY);
};

// DELETE STORAGE VALUE
export const deleteLocalStorage = () => {
  return localStorage.removeItem(KEY);
};

// CHECK IF STORAGE KEY IS CREATED
export const isLocalStorageCreated = () => {
  if (getLocalStorage() === null) {
    return false;
  } else {
    return true;
  }
};
