import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { stateActions } from '../../store/global-state';

// Components
import CreateButton from './CreateButton';
import Animation from '../Animation/Animation';

// LocalStorage control
import { setLocalStorageValue } from '../../store/localStorage';

import css from './InitialSetupScreen.module.css';

const InitialSetupScreen = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');

  const handleLocalStorageCreation = e => {
    e.preventDefault();
    if (!input || input.length === 0) {
      return;
    } else {
      setLocalStorageValue(input);
      dispatch(stateActions.setUserExists());
    }
  };

  return (
    <form className={css['form']} onSubmit={handleLocalStorageCreation}>
      <label htmlFor='name'>Hello, what is your name?</label>
      <input
        type='text'
        id='name'
        name='name'
        onChange={e => setInput(e.target.value)}
        placeholder='name'
        autoComplete='off'
      />
      {input.length !== 0 && (
        <Animation>
          <CreateButton />
        </Animation>
      )}
    </form>
  );
};

export default InitialSetupScreen;
