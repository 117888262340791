import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Container from "../../layout/Container";

import Animation from "../Animation/Animation";
import Header from "../../layout/Header";

import css from "./Location.module.css";

const Location = () => {
  return (
    <Container>
      <Header />
      <Animation>
        <div className={css["message"]}>
          <div>
            <p>🏢</p>
          </div>
          <p>Please choose location</p>
          <div className={css["btn-container"]}>
            <NavLink to="/vedbaek">
              <button>Vedbæk</button>
            </NavLink>
            <NavLink to="/aarhus">
              <button>Aarhus</button>
            </NavLink>
          </div>
        </div>
      </Animation>
    </Container>
  );
};

export default Location;
