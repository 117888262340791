import logo from "../assets/devoteam-logo.png";
import css from "./Layout.module.css";

const Header = () => {
  return (
    <header className={css["header"]}>
      <img src={logo} alt="Devoteam" />
    </header>
  );
};

export default Header;
