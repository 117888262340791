import css from './Message.module.css';

const GoBack = () => {
  const handleHelpers = () => {
    window.location.reload(false);
  };

  return (
    <button className={css['btn']} onClick={handleHelpers}>
      {`Go back`}
    </button>
  );
};

const Message = props => {
  const { type, message } = props.status;

  return (
    <div className={css['message']}>
      <div>
        <p>{type}</p>
      </div>
      <p>{message}</p>
      <GoBack />
    </div>
  );
};

export default Message;
