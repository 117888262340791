import css from './InitialSetupScreen.module.css';

const CreateButton = () => {
  return (
    <div className={css['form-button']}>
      {<button className={css['btn']}>Register</button>}
    </div>
  );
};

export default CreateButton;
