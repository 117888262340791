import { createSlice, configureStore } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',

  initialState: {
    localStorageState: false,
    messageType: false,
    helpers: false,
  },

  reducers: {
    setUserExists(state) {
      state.localStorageState = true;
    },

    setUserNull(state) {
      state.localStorageState = false;
    },

    setMessageType(state, action) {
      state.messageType = action.payload;
      state.helpers = true;
    },

    clearHelpers(state) {
      state.helpers = false;
    },
  },
});

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },
});

export const stateActions = appSlice.actions;
export default store;
