import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { stateActions } from './store/global-state';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Components
import Animation from './components/Animation/Animation';
import Container from './layout/Container';
import Header from './layout/Header';
import InitialSetupScreen from './components/InitialSetupScreen/InitialSetupScreen';
import Location from './components/Location/Location';
import MainScreen from './components/MainScreen/MainScreen';
import Message from './components/Message/Message';

// Storage
import { isLocalStorageCreated } from './store/localStorage';

const Content = () => {
  const userExists = useSelector(state => state.app.localStorageState);
  const messageType = useSelector(state => state.app.messageType);
  const dispatch = useDispatch();
  const [message, setMessage] = useState(false);

  useEffect(() => {
    if (isLocalStorageCreated()) {
      dispatch(stateActions.setUserExists());
    }
  }, [dispatch]);

  useEffect(() => {
    if (messageType) {
      setMessage(true);
    }
  }, [messageType]);

  return (
    <Fragment>
      <Container>
        <Header />
        {!userExists && !message && (
          <Animation>
            <InitialSetupScreen />
          </Animation>
        )}
        {userExists && !message && (
          <Animation>
            <MainScreen />
          </Animation>
        )}
        {messageType && message && (
          <Animation>
            <Message status={messageType} />
          </Animation>
        )}
      </Container>
    </Fragment>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/vedbaek' component={Content} />
        <Route exact path='/aarhus' component={Content} />
        <Route component={Location} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
