import { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

// Calendar related
import Calendar from "react-calendar";
import "react-slidedown/lib/slidedown.css";
import { SlideDown } from "react-slidedown";

// State/localStorage control
import { stateActions } from "../../store/global-state";
import { getLocalStorage, deleteLocalStorage } from "../../store/localStorage";

import css from "./MainScreen.module.css";

const MainScreen = () => {
  const dispatch = useDispatch();
  const [curDate, setCurDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const name = getLocalStorage();

  const location = useLocation().pathname.replace("/", "");

  const dateFormater = (date) => {
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0");
    let yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const isToday = (date) => {
    let today = new Date();
    let inputDate = dateFormater(date);
    today = dateFormater(today);
    if (today === inputDate) {
      return true;
    } else {
      return false;
    }
  };

  const handleCalendarSubmit = () => {
    if (curDate !== null) {
      if (
        window.confirm(
          `Register lunch for ${
            isToday(curDate) ? "today" : dateFormater(curDate)
          }?`
        )
      ) {
        fetch("/api/lunch/register", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: name,
            date: dateFormater(curDate),
            location: location,
          }),
        }).then((res) => {
          if (res.ok) {
            dispatch(
              stateActions.setMessageType({
                type: "🍱",
                message: `Lunch registered for ${
                  isToday(curDate) ? "today" : dateFormater(curDate)
                }`,
              })
            );
          } else {
            dispatch(
              stateActions.setMessageType({
                type: "❌",
                message: `There was an error with the server. Registration failed :(`,
              })
            );
          }
        });
      }
    } else {
      alert("Choose date!");
    }
  };

  const handleUserDeletion = () => {
    if (window.confirm(`Are you sure you want to remove ${name}`)) {
      dispatch(
        stateActions.setMessageType({
          type: "🗑️",
          message: `User ${name} deleted from local storage`,
        })
      );
      dispatch(stateActions.setUserNull());
      deleteLocalStorage();
    }
  };

  const handleDateSelection = (value) => {
    setCurDate(value);
  };

  useEffect(() => {
    setCurDate(new Date());
  }, []);

  return (
    <Fragment>
      <div className={css["info"]}>
        <p>{`Welcome, ${name}`}</p>
        <p>{`Office: ${capitalize(location)}`}</p>
      </div>
      <div className={css["btn-container"]}>
        <button
          className={showCalendar ? css["active"] : ""}
          onClick={() => setShowCalendar(!showCalendar)}
        >
          {showCalendar ? "Close" : "Open calendar"}
        </button>
        <SlideDown className="my-dropdown-slidedown">
          {showCalendar && (
            <Calendar
              onChange={handleDateSelection}
              minDetail="month"
              maxDetail="month"
              defaultView="month"
              value={curDate}
            />
          )}
        </SlideDown>
        <button onClick={handleCalendarSubmit}>
          {showCalendar ? "Register" : "Register for today"}
        </button>
        <NavLink to="/">
          <button>Switch office</button>
        </NavLink>
        <button onClick={handleUserDeletion}>Log out</button>
      </div>
    </Fragment>
  );
};

export default MainScreen;
