import { animated, useSpring } from 'react-spring';

const Animation = props => {
  const animation = useSpring({
    opacity: 1,
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  return <animated.div style={animation}>{props.children}</animated.div>;
};

export default Animation;
